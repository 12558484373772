/* client/src/global.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

body,
button,
input,
textarea {
  font-family: "Poppins", sans-serif;
}

body,
#root {
  background-color: #fffcdd;
}

body {
  color: #603414;
}

svg {
  color: white;
}

.MuiToolbar-root {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.MuiContainer-root {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.MuiGrid-item {
  padding-top: 16px !important;
}

.MuiCardContent-root,
.MuiBox-root,
svg {
  background-color: #fffcdd;

  color: #603414;
}

.MuiTypography-root > i > a,
.MuiTypography-root > a {
  color: #603414 !important;
}

.hero-text,
.hero-button {
  color: white;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  text-align: left;
}

@media (min-width: 1000px) {
  .hero-text {
    text-align: center !important;
  }
}

.hero-container {
  max-width: none !important;
}

.nav-link {
  color: white;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8); /* Adds a dark shadow for better readability */
  user-select: none; /* Makes text non-highlightable */
}

.nav-button {
  color: black;
}

.section-title {
  margin-bottom: 8px !important;
  font-weight: 600 !important;
}

.offer-title,
.essay-preview-title {
  font-weight: 600 !important;
}

.virtual-witnessing,
.physical-witnessing {
  box-shadow: 2px 2px 2px 2px rgba(248, 199, 0, 0.8) !important; /* Adds a shadow around all 4 sides for better readability */
}

.offer-numbers {
  margin-top: 16px !important;
  font-weight: 600 !important;
  text-align: right;
}

a {
  text-decoration: none;
}

.personal-curriculum > p > a,
.personal-curriculum > p > i > a {
  text-decoration: underline;
}

.savvycal-box {
  display: flex;
  justify-content: center;
}

.savvycal-button {
  color: #161510 !important;
  background-color: #f8c700;
  border: 1px solid #f8c700;
  font-size: 125%;
  padding: 8px 32px;
  border-radius: 4px;
  margin: 4px 8px 1rem 0;
  margin-bottom: 1rem;
  margin-left: 0px;
  margin-left: 0;
  margin-bottom: 1rem;
  white-space: nowrap;
  cursor: pointer;
}

.spaceholding-syllabus a {
  text-decoration: underline !important;
}

.spaceholding-syllabus h2 {
  font-weight: 700;
}
